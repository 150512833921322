import { render, staticRenderFns } from "./ReBiorganic.vue?vue&type=template&id=7c70c63c&scoped=true&"
import script from "./ReBiorganic.vue?vue&type=script&lang=js&"
export * from "./ReBiorganic.vue?vue&type=script&lang=js&"
import style0 from "./ReBiorganic.vue?vue&type=style&index=0&id=7c70c63c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c70c63c",
  null
  
)

export default component.exports